import React from "react"

const FeaturedBlog = ({ blog }) => {
    let imageURL = blog.Thumbnail.localFile.publicURL;
    let description = blog.Description.length > 256 ?
        blog.Description.slice(0, blog.Description.lastIndexOf(" ", 256)) + "..." :
        blog.Description;

    return (
        <div className="featured-blog-container">
            <div className="image-container">
                <a href={"/blog/" + blog.URL}>
                    <img src={imageURL} alt="Featured Blog" />
                </a>
            </div>
            <div className="content-container">
            <h3 className="blog-header"><a href={"/blog/" + blog.URL}>{blog.Header}</a></h3>
                <p className="blog-desc">{description} <a href={"/blog/" + blog.URL}>Read More</a></p>
            </div>
        </div>
    )
}

export default FeaturedBlog

import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useFlexSearch } from "react-use-flexsearch"
import { Formik, Form, Field } from 'formik'
import SearchIcon from "../icons/search"

import HelpSearchResult from "./HelpSearchResult"
import CloseIcon from "../icons/close"

const HelpSearch = ({ setShowHelpBar, d }) => {

    const data = useStaticQuery(graphql`
      query LocalSearchQuery {
        localSearchPages {
          index
          store
        }
      }
    `)

    const { localSearchPages: { index, store }, } = data

    const [query, setQuery] = useState([])
    const results = useFlexSearch(query, index, store);

    useEffect(() => {
        if (query.length > 0) {
            setShowHelpBar(false)
        } else {
            setShowHelpBar(true)
        }
    }, [query.length, setShowHelpBar])

    return (
        <>
            <div className="container m-auto w-full md:w-7/12 px-4">
                <Formik
                    initialValues={{ query: '' }}
                    onSubmit={(values, { setSubmitting }) => {
                        setQuery(values.query)
                        setSubmitting(false)
                    }}
                >
                    {({ resetForm }) => (
                        <Form className="mt-10 mb-4">
                            <div className="relative">
                                <Field className="bg-transparent border-b-2 focus:outline-none focus:border-blue border-gray-200 pl-4 pr-12 h-14 w-full"
                                    type="text"
                                    placeholder={d.searchPlaceholderForm}
                                    name="query" />
                                {query.length > 0 ?
                                    <div className="flex items-center absolute right-2 top-0 bottom-0">
                                        <button className="p-2 focus:outline-none" type="button"
                                            onClick={e => {
                                                setQuery([]);
                                                resetForm()
                                            }}
                                        >
                                            <CloseIcon width="20" fill="#333" />
                                        </button>
                                    </div>
                                    :
                                    <div className="flex items-center absolute right-2 top-0 bottom-0">
                                        <button className="p-2 focus:outline-none" type="submit" aria-label="Search">
                                            <SearchIcon />
                                        </button>
                                    </div>
                                }
                            </div>
                        </Form>
                    )}
                </Formik>
                {!query.length &&
                    <div className="md:text-sm text-center mt-4">
                        {d.searchFormText}
                    </div>
                }
            </div>
            {query.length > 0 &&
                <HelpSearchResult data={results} query={query} setShowHelpBar={setShowHelpBar} searchErrorFormText={d.searchErrorFormText} />
            }
        </>
    )
}

export default HelpSearch;
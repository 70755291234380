import React, { useState } from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import LayoutHeadFoot from "../components/Shared/Layouts/LayoutHeadFoot";
import Blogs from "../components/BlogPage/Blogs";
import FeaturedBlog from "../components/BlogPage/FeaturedBlog";

const BlogPage = ({ data }) => {
  const PAGE_SIZE = 12;

  data?.regular?.nodes?.sort((a, b) => {
    let aDate = new Date(a.PublishedAt);
    let bDate = new Date(b.PublishedAt);

    return aDate > bDate ? 1 : (aDate < bDate ? -1 : 0); 
  });

  data?.regular?.nodes?.sort(
    (a, b) => a.PublishedAt < b.PublishedAt ? 1 : -1
  );

  const [populars, setPopulars] =
    useState(data?.popular?.nodes.slice(0, PAGE_SIZE));
  const [recents, setRecents] =
    useState(data?.regular?.nodes.slice(0, PAGE_SIZE));

  const showPopulars = () => {
    let newSize = populars.length + PAGE_SIZE;
    setPopulars(data?.popular?.nodes.slice(0, newSize))
  }

  const showRecents = () => {
    let newSize = recents.length + PAGE_SIZE;
    setRecents(data?.regular?.nodes.slice(0, newSize))
  }

  if (data.featured === null) {
    data.featured = data.popular.nodes[0];
    data.popular.nodes.splice(0, 1);
    setPopulars(data?.popular?.nodes.slice(0, PAGE_SIZE))
  }

  return (
    <>
      <SEO seo={data.strapiBlogPage.metaData} />
      <LayoutHeadFoot>
      <div className="dn">
        <h1>
          <a href="https://www.chartrics.com/blog/">Chartrics Blog</a>
        </h1>
      </div>
      <div className="gradientBlue">
        <div className="py-14" />
        <div className="lg:container px-4 mb-24">
          <div className="main-container">
            <FeaturedBlog blog={data.featured} />
          </div>
          {
            populars.length !== 0 && (
              <>
                <h2 className="article-header">Most Popular Articles</h2>
                <Blogs blogs={populars}/>
                {
                  populars.length < data?.popular?.nodes.length && (
                    <button
                    className="show-more"
                    onClick={showPopulars}>
                    Show More
                    </button>
                    )
                  }
              </>
            )
          }
          {
            recents.length !== 0 && (
              <>
                <h2 className="article-header">Recent</h2>
                <Blogs blogs={recents}/>
                {
                  recents?.length < data?.regular?.nodes?.length && (
                    <button
                      className="show-more"
                      onClick={showRecents}>
                      Show More
                    </button>
                  )
                }
              </>
            )
          }
        </div>
      </div>
      </LayoutHeadFoot>
    </>
  )
}

export const query = graphql`
query blogs {
  strapiBlogPage {
    metaData {
      metaTitle
      metaDescription
      shareImage {
        localFile {
          publicURL
        }
      }
    }
  }
  regular: allStrapiBlogs(filter: {Type: {eq: "Regular"}}) {
    nodes {
      URL
      Description
      Header
      Thumbnail {
        localFile {
          publicURL
        }
      }
      Author
      categories {
        Name
        URL
      }
      PublishedAt
      Type
    }
  }
  popular: allStrapiBlogs(filter: {Type: {eq: "MostPopular"}}, sort: {fields: Order}) {
    nodes {
      URL
      Description
      Header
      Thumbnail {
        localFile {
          publicURL
        }
      }
      Author
      categories {
        Name
        URL
      }
      PublishedAt
      Type
    }
  }
  featured: strapiBlogs(Type: {eq: "Featured"}) {
    URL
    Description
    Author
    Header
    Thumbnail {
      localFile {
        publicURL
      }
    }
    categories {
      Name
      URL
    }
    PublishedAt
    Type
  }
}`

export default BlogPage
import React from "react"
import { graphql } from "gatsby"
import LayoutHeadFoot from "../components/Shared/Layouts/LayoutHeadFoot";
import Help from "../components/HelpPage/Help";
import SEO from "../components/seo";

const Helpage = ({ data }) => {

  const faqData = data.allStrapiFaq.edges[0].node;

  return (
    <>
      <SEO seo={faqData.metaData} />
      <LayoutHeadFoot>
        <Help data={faqData} />
      </LayoutHeadFoot>
    </>
  )
}

export const query = graphql`
query FaqQuery {
    allStrapiFaq {
        edges {
          node {
            metaData {
              metaTitle
              metaDescription
              shareImage {
                localFile {
                  publicURL
                }
              }
            }
            titleFaqSearch
            searchPlaceholderForm
            searchFormText
            searchErrorFormText
            titleFaqElement
            contactUsBanner
            faqElement {
              id
              faqTabName
              accordion {
                body
                header
                id
              }
            }
          }
        }
    }
}`

export default Helpage

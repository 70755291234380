import React, { useState } from "react"
import classNames from "classnames";
import AccordionItem from "../Shared/AccordionItem";
import HelpMobileCollapse from "./HelpMobileCollapse";


const HelpSidebar = ({ data, titleFaqElement }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    return (
        <div className="container pt-4 pb-12 mb-36 px-4">
            <div className="text-center mb-0 sm:mb-16 font-bold">
                <h2>{titleFaqElement}</h2>
            </div>
            {/* Desktop */}
            <div className="hidden md:flex md:flex-row">
                <div className="w-full sm:w-1/5 pb-16 pt-1.5">
                    {data.map((d, index) => {
                        return (
                            <div key={index}>
                                <button
                                    className={classNames
                                        ("my-1.5 py-1 focus:outline-none text-left w-full text-base md:text-sm	lg:text-base",
                                            {
                                                "text-black": index !== selectedIndex,
                                                "text-black-dark font-bold": index === selectedIndex,
                                            }
                                        )}
                                    onClick={() => setSelectedIndex(index)}
                                    key={index}
                                >
                                    {d.faqTabName}
                                </button>
                            </div>
                        )
                    }
                    )}
                </div>
                <div className="mx-3 lg:mx-6 border-r border-black-dark hidden sm:block" />
                <div className="w-full w-4/5 helpAcc">
                    {data[selectedIndex].accordion.map((el) => (
                        <AccordionItem key={el.id} header={el.header} body={el.body} />
                    ))}
                </div>
            </div>
            {/* End Desktop */}
            {/* Mobile */}
            <div className="mt-12 flex flex-col md:hidden">
                {data.map(d => (
                    <HelpMobileCollapse data={d} key={d.id} />
                ))}
            </div>
            {/* End Mobile */}
        </div>
    )
}

export default HelpSidebar
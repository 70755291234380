import React from "react"
import { graphql } from "gatsby"
import PrivacyPolicy from "../components/PrivacyPolicyPage/PrivacyPolicy"
import LayoutHeadFoot from "../components/Shared/Layouts/LayoutHeadFoot";
import SEO from "../components/seo";

const Privacy = ({ data }) => {

  const privacyData = data.allStrapiPrivacyPolicy.edges[0].node;

  return (
    <>
      <SEO seo={privacyData.metaData} />
      <LayoutHeadFoot>
        <PrivacyPolicy data={privacyData} />
      </LayoutHeadFoot>
    </>
  )
}

export const query = graphql`
query PrivicyQuery {
  allStrapiPrivacyPolicy {
    edges {
      node {
        metaData {
          metaTitle
          metaDescription
          shareImage {
            localFile {
              publicURL
            }
          }
        }
        content
      }
    }
  }
}`

export default Privacy



import React, { useState } from "react"
import classNames from "classnames";
import ArrowBottom from "../icons/arrow-bottom";

const AccordionItem = ({ header, body }) => {

    const [isToggled, setIsToggled] = useState(true);

    return (
        <div className="collapseEl px-0 sm:px-3">
            <button className="focus:outline-none flex w-full justify-between items-start py-2 my-2"
                onClick={() => setIsToggled(!isToggled)} aria-label="Collapse">
                <div className="flex">
                    <div className="text-left font-bold">
                        {header}
                    </div>
                </div>
                <div className={classNames
                    ("origin-center transform mt-2 flex-shrink-0",
                        {
                            "rotate-0": isToggled,
                            "rotate-180": !isToggled,
                        }
                    )}>
                    <ArrowBottom />
                </div>
            </button>

            <div className={classNames
                ("pb-6",
                    {
                        "flex": isToggled,
                        "hidden": !isToggled,
                    }
                )}
            >
                <div className="text-editor" dangerouslySetInnerHTML={{ __html: body }} />
            </div>
        </div>
    )
}

export default AccordionItem;


import React, { useEffect, useRef, useState } from "react"
// import { useLockBodyScroll, useOnClickOutside } from "../../utils/hooks"
import { useLockBodyScroll } from "../../utils/hooks"
import CloseIcon from "../icons/close"
import ContactUsForm from "./ContactUsForm"

const ContactUsModal = ({ location, showModal, setShowModal }) => {
  useLockBodyScroll()

  const ref = useRef()
  const [success, setSuccess] = useState(false)

  //useOnClickOutside(ref, () => setShowModal(false))

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setShowModal(false)
      }, 4000)
    }
  }, [success, setShowModal])

  const modalHandle = () => {
    setShowModal(!showModal)
  }

  return (
    <div className="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div
        ref={ref}
        className="custom-scrollbar bg-white rounded-none sm:rounded-xl shadow-lg overflow-auto max-h-90 w-11/12 lg:w-5/12"
      >
        <div className="flex justify-end p-6 pb-0">
          <button
            onClick={modalHandle}
            className="text-black justify-center flex focus:outline-none"
          >
            <CloseIcon width="20" fill="#333" />
          </button>
        </div>
        {!success ? (
          <>
            <div className="px-6 sm:px-10 py-4">
              <ContactUsForm
                location={location}
                setSuccess={setSuccess}
              />
            </div>
          </>
        ) : (
          <div className=" mb-8 text-center">
            Thank you for your interest in Chartrics. 
            <br/>
            We have received your request and someone will reach out to you shortly.
          </div>
        )}
        
      </div>
    </div>
  )
}

export default ContactUsModal
import React from "react"
import AccordionItem from "../Shared/AccordionItem"

const HelpSearchResult = ({ data, query, searchErrorFormText }) => {

    if (data.length === 0 && query.length > 0) {
        return (
            <div className="text-center md:text-sm text-red-500">
                {searchErrorFormText}
            </div>
        )
    }

    return (
        <div className="mb-36">
            {/* <div className="mt-24 py-4 bg-blue-light" >
                <h2 className="text-center font-bold">Featured Articles</h2>
                {data && data.map(({ id, url, textButton }) => {
                    return url ? <div className="mb-2 text-center" key={id}>
                        <Link style={{ color: "#4286F4", textDecoration: "underline", }} to={`/${url}/`}>
                            {textButton}
                        </Link>
                    </div> : null
                })}
            </div > */}
            <div className="container mt-12 helpAcc">
                <h2 className="text-center mb-12 font-bold">Frequently Asked Questions</h2>
                {data && data.map(({ header, body, id }) => (
                    header || body ? <AccordionItem key={id} header={header} body={body} /> : null
                ))}
            </div>
        </div>
    )
}

export default HelpSearchResult




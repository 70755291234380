import React, { useState } from "react"
import classNames from "classnames";
import ArrowBottom from "../icons/arrow-bottom";

const HelpMobileCollapse = ({ data }) => {

    const [isToggled, setIsToggled] = useState(false);

    return (
        <div className="collapseEl">
            <button className="focus:outline-none flex w-full justify-between items-start py-4 px-2"
                onClick={() => setIsToggled(!isToggled)} aria-label="Collapse">
                <div className="text-lg">
                    {data.faqTabName}
                </div>
                <div className={classNames
                    ("origin-center transform mt-1 flex-shrink-0",
                        { "rotate-0": isToggled, "rotate-180": !isToggled, }
                    )}>
                    <ArrowBottom />
                </div>
            </button>
            {data.accordion.map((el) => {
                return (
                    <div key={el.id} className={classNames
                        ("py-3 px-2",
                            { "flex": isToggled, "hidden": !isToggled, }
                        )}>
                        <div className="flex flex-col">
                            <div className="text-left font-semibold">
                                {el.header}
                            </div>
                            <div className="text-editor" dangerouslySetInnerHTML={{ __html: el.body }} />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default HelpMobileCollapse;


import React, { useState } from "react"
import HelpSearch from "./HelpSearch"
import HelpSidebar from "./HelpSidebar"
import ContactUsModal from "../ContactUsPage/ContactUsModal"

const Help = ({ data }) => {

    const [showModal, setShowModal] = useState(false);
    const [showHelpBar, setShowHelpBar] = useState(true);

    const modal = () => {
        setShowModal(true)
    }

    return (
        <>
            <div className={showHelpBar ? "bg-blue-light" : ""}>
                <div className="flex flex-row py-20 ">
                    <div className="flex flex-col w-full">
                        <div className="text-editor" dangerouslySetInnerHTML={{ __html: data.titleFaqSearch }} />
                        <HelpSearch showHelpBar={showHelpBar} setShowHelpBar={setShowHelpBar} d={data} />
                    </div>
                </div>
            </div>
            {showHelpBar &&
                <HelpSidebar data={data.faqElement} titleFaqElement={data.titleFaqElement} />
            }
            <div className="bg-blue py-6">
                <div className="lg:container px-8 flex flex-col md:flex-row items-center justify-center">
                    <div className="mx-4 text-white text-center mb-4 md:mb-0">{data.contactUsBanner}</div>
                    <div>
                        <button id="helpContactUs" onClick={modal} className="rounded-lg bg-white border-white py-2 px-6 font-semibold	text-base text-blue-dark whitespace-nowrap">
                            Contact Us
                        </button>
                        {showModal &&
                            <ContactUsModal location="Help Page" showModal={showModal} setShowModal={setShowModal} />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Help


